import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { leadLanguage } from 'utils/functions';

interface IBookUpsells {
  [key: string]: {
    url?: string;
    image: string;
  };
}

interface BundleItem {
  name: string;
  link: string;
}

interface OrderSummaryProps {
  title: string;
  trialFreeText?: string;
  wyXSellInfo?: string;
  xSellDownloadText?: string;
  scrollToTYSection: () => void;
  scrollToWY: () => void;
  includesXSell?: boolean;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
}

const bookUpsells: IBookUpsells = {
  'brainway-mental-health-book': {
    url: 'https://firebasestorage.googleapis.com/v0/b/brainway-app-prod.appspot.com/o/upsells%2FEN-Mental-Workbook.pdf?alt=media&token=c3622780-b823-4d20-a7fa-a05c762482b1',
    image: 'success/mental-health.png',
  },
  'brainway-ultimate-sleep-guide': {
    url: 'https://firebasestorage.googleapis.com/v0/b/brainway-app-prod.appspot.com/o/upsells%2FEN-The-Ultimate-Sleep-Guide.pdf?alt=media&token=f3fd0134-fcf9-4442-934a-2693b1952028',
    image: 'success/sleep-guide.png',
  },
  'brainway-productivity-handbook': {
    url: 'https://firebasestorage.googleapis.com/v0/b/brainway-app-prod.appspot.com/o/upsells%2FEN-Productivity-Handbook.pdf?alt=media&token=821fa403-1554-41f9-b943-a972e267102d',
    image: 'success/productivity-handbook.png',
  },
  'upsell-brainway-break-habits': {
    url: 'https://firebasestorage.googleapis.com/v0/b/brainway-app-prod.appspot.com/o/upsells%2FEN-Bad-Habits.pdf?alt=media&token=6b83e1a0-50e8-49a5-9dba-a8b7ad091d05',
    image: 'success/self-discipline.png',
  },
  'upsell-brainway-intellectual-curiosity': {
    url: 'https://firebasestorage.googleapis.com/v0/b/brainway-app-prod.appspot.com/o/upsells%2FIntellectual_Curiosity_Guide.pdf?alt=media&token=d6e8c76d-630a-4da0-96c9-d7d9766d0f95',
    image: 'success/curiosity-guide.png',
  },
};

const OrderSummaryV2: FC<OrderSummaryProps> = ({
  title,
  trialFreeText,
  wyXSellInfo,
  xSellDownloadText,
  scrollToTYSection,
  scrollToWY,
  includesXSell,
  upsellsKeys,
  bundlePhoto,
}) => {
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-bw';
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products } = useSelector(
    (state: AppState) => state.user,
  );

  const selectedProduct = getLocalisedProduct(selected_plan!);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const productNames = useSelector(
    (state: AppState) => state?.config?.productNameMap,
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });
    if (result === '') {
      return '12345';
    }
    return result;
  };

  const bundleItems = (): BundleItem[] => {
    let items: BundleItem[] = [];

    sortedUpsells.forEach(key => {
      const downloadLink = bookUpsells[key].url;
      const name = productNames[key];

      items.push({ name: name, link: downloadLink ?? '' });
    });

    return items;
  };

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);

    if (upsellProduct?.key.includes('brainway-99-years')) {
      return;
    }

    if (upsellProduct?.key.includes('brainway_walking-yoga_upsell')) {
      return (
        <TrialContainer key={index}>
          <TrialDetails>
            <TrialImage src="success/wy-app.png" alt="Walking Yoga app" />
            <TrialColumn>
              <TrialItemContainer>
                <TrialTitle type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {upsellProduct?.name}
                </TrialTitle>
                <TrialPriceContainer>
                  <OldPrice type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {upsellProduct?.currency}
                    {upsellProduct?.key.includes('6-month')
                      ? (Number(upsellProduct?.finalPrice) / 2).toFixed(2)
                      : upsellProduct?.finalPrice}
                  </OldPrice>
                  <FreeLabel type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {trialFreeText ?? 'Free'}
                  </FreeLabel>
                </TrialPriceContainer>
              </TrialItemContainer>
              <DownloadButton onClick={scrollToWY}>
                {xSellDownloadText ?? 'Download here'}
              </DownloadButton>
            </TrialColumn>
          </TrialDetails>
          <TrialInfo>{wyXSellInfo ?? 'Free 30-day trial'}</TrialInfo>
        </TrialContainer>
      );
    }

    if (upsellProduct?.key.includes('brainway-bundle-sle-men-pro')) {
      return (
        <TrialContainer key={index}>
          <TrialDetails>
            <TrialImage
              src={`funnel/upsells/self_growth_bundle/en/bundle-${getBundlePhoto()}.png`}
              alt="Bundle"
            />
            <TrialColumn>
              <TrialItemContainer>
                <TrialTitle type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {productNames[upsellProduct?.key]}
                </TrialTitle>
                <TrialPriceContainer>
                  <OldPrice type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {upsellProduct?.currency}
                    {upsellProduct?.regularPrice}
                  </OldPrice>
                  <Price type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {upsellProduct?.currency}
                    {upsellProduct?.finalPrice}
                  </Price>
                </TrialPriceContainer>
              </TrialItemContainer>
              {bundleItems().map((item, index) => (
                <DownloadLink
                  key={`bundle-item-${index}`}
                  href={item.link}
                  target="_blank"
                >
                  {item.name}
                </DownloadLink>
              ))}
            </TrialColumn>
          </TrialDetails>
        </TrialContainer>
      );
    }

    const bookUpsellInfoObj = bookUpsells[upsellProduct?.key!] ?? undefined;

    return (
      <TrialContainer key={index}>
        <TrialDetails>
          <TrialImage src={bookUpsellInfoObj.image} alt="Walking Yoga app" />
          <TrialColumn>
            <TrialItemContainer>
              <TrialTitle type={isTablet ? 'body' : 'h3400'} color="dark80">
                {productNames[upsellProduct?.key]}
              </TrialTitle>
              <TrialPriceContainer>
                <OldPrice type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {upsellProduct?.currency}
                  {upsellProduct?.regularPrice}
                </OldPrice>
                <Price type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {upsellProduct?.currency}
                  {upsellProduct?.finalPrice}
                </Price>
              </TrialPriceContainer>
            </TrialItemContainer>
            {bookUpsellInfoObj.url && (
              <DownloadLink href={bookUpsellInfoObj.url} target="_blank">
                {xSellDownloadText ?? 'Download here'}
              </DownloadLink>
            )}
          </TrialColumn>
        </TrialDetails>
        {/* <TrialInfo>{wyXSellInfo ?? 'Free 30-day trial'}</TrialInfo> */}
      </TrialContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce((sum, p) => {
    if (p?.hasTrial) {
      return sum + parseFloat(p?.discountedTrialPrice || '0');
    }

    return sum + parseFloat(p?.finalPrice || '0');
  }, 0);

  const totalPrice = (
    parseFloat(selectedProduct?.finalPrice!) + parseFloat(String(upsellsPrice))
  ).toFixed(2);
  const totalPriceTrial = (
    parseFloat(selectedProduct?.discountedTrialPrice!) +
    parseFloat(String(upsellsPrice))
  ).toFixed(2);

  const resolveProductName = (productName: string) => {
    if (includesXSell) {
      return productName.replace('Brain Way', 'Brainway');
    }

    if (leadLanguage(quiz) === 'es') {
      return productName.replace('de Brainway', '');
    }

    return productName.replace('Brainway ', '').replace('Brain Way', '');
  };

  const lifetimeProduct = upsell_products.find(item =>
    item.key.includes('brainway-99-years'),
  );

  const localisedLifetime = getLocalisedProduct(lifetimeProduct);

  return (
    <Container>
      <Heading type={isTablet ? 'h2600' : 'h1'}>{title}</Heading>
      <SummaryContainer>
        <TrialContainer>
          <TrialDetails>
            <TrialImage src="success/bw-app1.png" alt="Walking Yoga app" />
            <TrialColumn>
              <TrialItemContainer>
                <TrialTitle type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {resolveProductName(selectedProduct?.name!)}
                </TrialTitle>
                <TrialPriceContainer>
                  <OldPrice type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {selectedProduct?.currency}
                    {selectedProduct?.trialOldAmount}
                  </OldPrice>
                  <Price type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {selectedProduct?.currency}
                    {selectedProduct?.discountedTrialPrice}
                  </Price>
                </TrialPriceContainer>
              </TrialItemContainer>
              <DownloadButton onClick={scrollToTYSection}>
                {'Download here'}
              </DownloadButton>
            </TrialColumn>
          </TrialDetails>
          {lifetimeProduct && (
            <TrialInfoContainer>
              <TrialInfo>
                {productNames['brainway-99-years']
                  .replace('Brainway', '')
                  .trim()}
              </TrialInfo>
              <Price>
                {localisedLifetime.currency}
                {localisedLifetime.finalPrice}
              </Price>
            </TrialInfoContainer>
          )}
        </TrialContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            Total
          </Text>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            {selectedProduct?.currency}
            {selectedProduct?.hasTrial ? totalPriceTrial : totalPrice}
          </Text>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummaryV2;

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  gap: 3rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Text)`
  @media ${tablet} {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
  gap: 0.375rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const TrialItemContainer = styled(OrderItemContainer)`
  padding-bottom: 0;
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid rgba(64, 86, 185, 0.4);
  padding-top: 1.5rem;
  @media ${tablet} {
    padding-top: 1.5rem;
  }
`;

const TrialPriceContainer = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
  align-self: flex-start;
`;

const Price = styled(Text)`
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.018rem;

  @media ${tablet} {
    font-size: 1rem;
    line-height: 130%;
    letter-spacing: -0.016rem;
  }
`;

const OldPrice = styled(Price)`
  text-decoration: line-through;
  color: #5f5f6a;
  text-align: right;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.016rem;
`;

const FreeLabel = styled(Text)`
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.25rem;
  background: #12845e;
  padding: 0.25rem 0.5rem;

  @media ${tablet} {
    font-size: 0.625rem;
  }
`;

const TrialTitle = styled(Text)`
  color: #1c1c28;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.024rem;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const TrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;

  @media ${tablet} {
    padding-bottom: 1.5rem;
    gap: 0.5rem;
  }
`;

const TrialDetails = styled.div`
  display: flex;
  gap: 1.5rem;

  @media ${tablet} {
    gap: 0.5rem;
  }
`;

const TrialImage = styled(DynamicImage)`
  width: 3.5rem;
  height: 5rem;

  @media ${tablet} {
    width: 2.125rem;
    height: 3rem;
  }
`;

const TrialColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @media ${tablet} {
    gap: 0.25rem;
  }
`;

const DownloadButton = styled.button`
  color: #12845e;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 1.625rem */
  letter-spacing: -0.03rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  background: none;
  border: none;
  text-align: left;
  width: fit-content;
  cursor: pointer;

  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: -0.012rem;
  }
`;

const DownloadLink = styled.a`
  color: #12845e !important;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.03rem;
  background: none;
  border: none;
  text-align: left;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline !important;

  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: -0.012rem;
  }
`;

const TrialInfo = styled.p`
  color: #555770;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01375rem;
  padding-left: 4.5rem;

  @media ${tablet} {
    font-size: 1rem;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.024rem;
    padding-left: 2.3125rem;
  }
`;

const TrialInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
